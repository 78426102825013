<template >
  <div
    class="m-nav-bar overlap on-top uk-sticky"
    data-controller="ControllerNavigation"
    uk-sticky="animation: uk-animation-slide-top; show-on-up: true; sel-target: .m-nav-bar__wrapper.uk-navbar-container"
    style="background-color: rgb(17, 21, 28)"
  >
    <div class="m-nav-bar__wrapper uk-navbar-container uk-navbar df-row-ac-jc">
      <a
        class="a-wolf-logo m-nav-bar__logo uk-logo"
        href=""
        target="_self"
        title="wolf-logo"
      >
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 174 52.6"
          width="174"
          height="53"
          enable-background="new 0 0 174 52.6"
          xml:space="preserve"
        >
          <path
            fill="#FFFFFF"
            d="M62.3,0l-12,52.6h52.1l12-52.6H62.3z M117.5,5.3L108,47h25.5l3.3-14.3H128l6.3-27.4H117.5z M147.1,5.3L137.5,47h16.7l2.9-12.7h10.2l2.7-11.9h-10.2l1-4.5h10.2L174,5.3H1471z M42.3,5.2l-3.4,15.4L35.4,5.2H21l-3.4,15.3L14.2,5.2H0L9.3,47h14.7L27.8,30L31.6,47h15.5l9.3-41.7H42.3z"
          ></path>
          <path
            fill="#E10000"
            d="M59.8,26.3C59.8,13.8,70,3.6,82.5,3.6c0.9,0,1.7,0,2.6,0.1C95.5,5,103.8,13.2,105,23.7c1.4,12.4-7.5,23.7-19.9,25.1c-0.9,0.1-1.7,0.1-2.6,0.1C70,48.9,59.8,38.8,59.8,26.3C59.8,26.3,59.8,26.3,59.8,26.3"
          ></path>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import { Popup } from "vant";

export default {
  components: {
    [Popup.name]: Popup,
  },
  props: {
    logoBlue: {
      default: false,
    },
    menuBlack: {
      default: false,
    },
  },
  data() {
    return {
      show: false,
      showContact: false,
      activeName: "home",
    };
  },
  methods: {
    handleContact() {
      this.show = false;
      this.showContact = true;
    },
    handleMenu(flag) {
      this.show = false;

      let path = "";
      switch (flag) {
        case "home":
          path = "/home";
          break;
        case "wolf":
          path = "/wolf";
          break;
        case "huan":
          path = "/huan";
          break;
        case "about":
          path = "/about";
          break;
      }
      localStorage.setItem("menuactive", flag);
      this.activeName = flag;
      this.$router.push({
        path: path,
      });
    },
  },
  mounted() {
    let activeName = localStorage.getItem("menuactive");
    if (activeName) {
      this.activeName = activeName || "home";
    }
  },
};
</script>


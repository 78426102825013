<template >
  <div class="w-100 df-col-as" style="background-color: #fff">
    <Header></Header>
    <div class="flex-1">
      <div class="stage-wrapper uk-flex">
        <div class="image-wrapper"></div>
      </div>
      <div
        class="df-col-ac-jc px-3"
        style="padding-top: 62px; padding-bottom: 62px"
      >
        <div>
          <div class="pf-display2">网站维护公告</div>
          <div style="padding-top: 36px" class="pf-headline">
            <div>
              亲爱的沃乐夫用户: <br />
              从即日起，我们将对沃乐夫中国官网进行升级，在此期间网站将暂停服务。<br />
              由此给您带来的不变敬请谅解。<br />
              <div style="padding-top: 50px">
                如需获取最新信息，请关注微信公众号及官方店铺
              </div>
            </div>
          </div>
          <div class="df-row-ac flex-wrap" style="">
            <div
              class="border px-46p pt-25p df-col-ac uk-img"
              style="height: 280px; margin-top: 50px"
            >
              <div class="pf-headline">微信扫码</div>
              <div class="pf-subhead">关注德国沃乐夫</div>
              <div class="pt-16p">
                <img
                  src="../../assets/wechat.png"
                  alt=""
                  style="width: 130px; height: 130px"
                />
              </div>
            </div>
            <div
              class="border px-46p pt-25p df-col-ac uk-img2 uk-img"
              style="height: 280px; margin-top: 50px"
            >
              <div class="pf-headline">淘宝扫码</div>
              <div class="pf-subhead">进入沃乐夫旗舰店</div>

              <div class="pt-16p">
                <img
                  src="../../assets/tmall.png"
                  alt=""
                  style="width: 130px; height: 130px"
                />
              </div>
              <div
                class="pf-subhead df-row-ac pointer"
                style="padding-top: 5px"
              >
                <a
                  href="https://wolefu.tmall.com/shop/view_shop.htm?spm=a230r.1.14.5.15335984KAPN6q&user_number_id=3973520610"
                  target="_blank"
                  rel="noopener noreferrer"
                  >点击进入天猫旗舰店</a
                >
                <span
                  ><svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <polyline
                      fill="none"
                      stroke="#000"
                      stroke-width="1.2"
                      points="1 1 6 6 1 11"
                    ></polyline></svg
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { Button, Popup } from "vant";
import Header from "../header/index";
import Footer from "../footer/index";

export default {
  components: {
    [Button.name]: Button,
    [Popup.name]: Popup,
    Footer,
    Header,
  },
  data() {
    return {
      show: false,
      activeName: "",
    };
  },
  methods: {
    handleMenu(flag) {
      let path = "";
      switch (flag) {
        case "wolf":
          path = "/wolf";
          break;
        case "huan":
          path = "/huan";
          break;
        case "about":
          path = "/about";
          break;
      }
      localStorage.setItem("menuactive", flag);
      this.activeName = flag;
      this.$router.push({
        path: path,
      });
    },
    handleNull() {},
  },
};
</script>

<style lang="scss">
</style>

<template lang='pug'>
.w-100.text-999.pf-subhead.border-top(style="line-height: 48px")
  .py-1.text-center
    div © 2023 WOLF | 沪ICP备17025560号-1
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
</style>
